/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/modern.scss';

//jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;

// Main
import "./modules/bootstrap";
import "./modules/feather";
//import "./modules/fullcalendar";
import "./modules/moment";
import "./modules/sidebar";
import "./modules/theme";
//import "./modules/toastr";
import "./modules/user-agent";

// Charts
import "./modules/chartjs";
import "./modules/apexcharts";

// Forms
import "./modules/daterangepicker";
import "./modules/datetimepicker";
//import "./modules/markdown";
//import "./modules/mask";
//import "./modules/quill";
import "./modules/select2";
//import "./modules/validation";
//import "./modules/wizard";

// Maps
//import "./modules/vector-maps";

// Tables
import "./modules/datatables";
