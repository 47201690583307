/*import 'script-loader!datatables.net'
import 'script-loader!datatables.net-bs4'
import 'script-loader!datatables.net-buttons'
import 'script-loader!datatables.net-buttons/js/buttons.html5.js'
import 'script-loader!datatables.net-buttons/js/buttons.print.js'
import 'script-loader!datatables.net-buttons-bs4'
import 'script-loader!datatables.net-responsive'*/
import 'datatables.net'
import 'datatables.net-bs4'
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-buttons-bs4'
import 'datatables.net-responsive'
