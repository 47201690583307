$(function() {
  /* Sidebar toggle behaviour */
  $(".sidebar-toggle").on("click touch", function() {
    $(".sidebar").toggleClass("toggled").one('transitionend', function () {
      $($.fn.dataTable.tables( true ) ).DataTable().columns.adjust();
    });

  });

  const active = $(".sidebar .active");

  if (active.length && active.parent(".collapse").length) {
    const parent = active.parent(".collapse");

    parent.prev("a").attr("aria-expanded", true);
    parent.addClass("show");
  }

});
